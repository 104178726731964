import store from 'store';
import firebase from './firebase';


class Auth {
  static isAuthenticated() {
    const user = firebase.auth().currentUser;

    return Boolean(user);
  }

  static signOut() {
    firebase.auth().signOut()
    .then(() => {
      store.clearAll();
    })
    .catch((error) => {
      if (error) {
        console.log({ error });
      }
    });
  }
}

export default Auth;
