import React from 'react';

import Mission from '../components/sections/Mission';
import HowItWorks from '../components/sections/HowItWorks';
import Impact from '../components/sections/Impact';
import Benefits from '../components/sections/Benefits';
import Testimonials from '../components/sections/Testimonials';
import FAQs from '../components/sections/FAQs';
import * as Util from '../Util';


class Home extends React.Component {
  componentDidMount() {
    if (Util.isProduction()) {
      window.amplitude.getInstance().logEvent('Visited Homepage');
      window.fbq('track', 'ViewContent');
    }
  }

  render() {
    const isAuthed = this.props.isAuthed;
    const history = this.props.history;

    return (
      <div className="App Homepage">
        <section className="HeroSection">
          <div className="HeroSection__inner">
            <h1 className="brand-name">Stop Your Paper Junk Mail Fast</h1>
            <h2 className="tagline">Lesser. Simpler. Happier.</h2>
    
            <div className="cta-section">
              { 
                isAuthed ? 
                <button className="button CTAButton" onClick={ () => { history.push('/dashboard') } }>Go to Dashboard</button> :
                <button className="button CTAButton" onClick={ () => { history.push('/start/step1') } }>Get Started</button>
              }
            </div>
          </div>

        </section>
        <Benefits />
        <HowItWorks />
        <Impact />
        <Testimonials />
        <Mission />
        <FAQs />
        <div className="ParallaxSection"></div>
        <section className="ClosingSection">
          <h1>Stop Junk Mail Now</h1>
          <p>
            Reduce paper waste and save our planet. Each mail matters.
          </p>
  
          <div className="cta-section">
            { 
              isAuthed ? 
              <button className="button CTAButton" onClick={ () => { history.push('/dashboard') } }>Go to Dashboard</button> :
              <button className="button CTAButton" onClick={ () => { history.push('/start/step1') } }>Sign Up Now</button>
            }
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
