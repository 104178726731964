import React from 'react';

import './HowItWorks.scss';
import step1 from '../../assets/steps/step-1.jpg';
import step2 from '../../assets/steps/step-2.jpg';
import step3 from '../../assets/steps/step-3.jpg';

class HowItWorks extends React.Component {
  render() {
    return (
      <section id="how-it-works" className="HowItWorksSection">
        <h2>How It Works</h2>
        <div className="HowItWorksSection__steps">
          <div className="HowItWorksSection__step">
            <div className="title">Step 1</div>
            <div className="description">
              Enter the sender's name from the mail you want to opt out.
            </div>
            <div className="image">
              <img src={step1} alt="Step 1" />
            </div>
          </div>
          <div className="HowItWorksSection__step">
            <div className="title">Step 2</div>
            <div className="description">
              Confirm the recipient name and mailing address, then create the opt-out request.
            </div>
            <div className="image">
              <img src={step2} alt="Step 2" />
            </div>
          </div>
          <div className="HowItWorksSection__step">
            <div className="title">Step 3</div>
            <div className="description">
              Check the status of your opt-out requests on your dashboard. Usually the mail will be removed in less than 4 weeks.
            </div>
            <div className="image">
              <img src={step3} alt="Step 3" />
            </div>
          </div>
        </div>

        <div className="HowItWorksSection__highlights">
          <div className="highlight">
            Reduce <span className="underline">1,058 oz</span> of CO<sub>2</sub> per year
          </div>
          <div className="highlight">
            Save you <span className="underline">1 hour</span> per week
          </div>
        </div>
      </section>
    );
  }
}

export default HowItWorks;