import loadable from '@loadable/component';

import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import firebase from './firebase';
import * as Config from './constants/Config';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import StepOne from './pages/start/StepOne';
import StepTwo from './pages/start/StepTwo';
import './App.css';

const Dashboard = loadable(() => import('./pages/Dashboard'));
const Request = loadable(() => import('./pages/Request/Request'));
const AdminDashboard = loadable(() => import('./pages/AdminDashboard/AdminDashboard'));
const SignIn = loadable(() => import('./pages/SignIn'));
const ForgotPassword = loadable(() => import('./pages/ForgotPassword/ForgotPassword'));
const ResetPassword = loadable(() => import('./pages/ResetPassword/ResetPassword'));
const Privacy = loadable(() => import('./pages/Privacy'));
const Terms = loadable(() => import('./pages/Terms'));
const Payment = loadable(() => import('./pages/Payment/Payment'));

const Recipients = loadable(() => import('./pages/Recipient/Recipients'));
const Recipient = loadable(() => import('./pages/Recipient/Recipient'));
const Learn = loadable(() => import('./pages/Learn/Learn'));


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isAuthenticated: false,
      requests: []
    };
  }

  unsubscribeRequestsListener = null;

  componentDidMount() {
    window.amplitude.getInstance().init(Config.AMPLITUDE_KEY);

    let { isLoading, isAuthenticated } = this.state;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        isAuthenticated = true;
      } else {
        isAuthenticated = false;
      }

      isLoading = false;
      this.setState({ isAuthenticated, isLoading });
    });
  }

  render() {
    const { isLoading, isAuthenticated } = this.state;
    return (
      <Router>
        {
          isLoading ? null :
          <div>
            <Header isAuthed={isAuthenticated} />
            <Route
              exact path="/"
              render={(props) => <Home {...props} isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/dashboard"
              render={(props) => <Dashboard {...props} isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/requests/add"
              render={(props) => <Request {...props} isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/recipients"
              render={(props) => <Recipients {...props} isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/recipients/add"
              render={(props) => <Recipient {...props} type="add" isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/recipients/edit/:id"
              render={(props) => <Recipient {...props} type="edit" isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/learn"
              render={(props) => <Learn {...props} isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/start/step1"
              render={(props) => <StepOne {...props} isAuthed={isAuthenticated} />}
            />
            <Route
              exact path="/start/step2"
              render={(props) => <StepTwo {...props} />}
            />
            <Route
              exact path="/subscription"
              render={(props) => <Payment {...props} isAuthed={isAuthenticated} />}
            />

            <Route
              exact path="/signin"
              render={(props) => <SignIn {...props} isAuthed={isAuthenticated} />}
            />

            <Route
              exact path="/password/forgot"
              render={(props) => <ForgotPassword {...props} isAuthed={isAuthenticated} />}
            />

            <Route
              exact path="/password/reset"
              render={(props) => <ResetPassword {...props} isAuthed={isAuthenticated} />}
            />

            <Route
              exact path="/terms"
              render={(props) => <Terms {...props} />}
            />
            <Route
              exact path="/privacy"
              render={(props) => <Privacy {...props} />}
            />

            <Route
              exact path="/admin"
              render={(props) => <AdminDashboard {...props} isAuthed={isAuthenticated} />}
            />

            <Footer />
          </div>
        }
      </Router>
    );
  }
}

export default App;
