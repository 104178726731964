import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../logo-dot.png';


const Logo = () => {
  const path = '/';
  return (
    <Link to={path} className="Logo no-style">
      <img src={logo} alt="Wabi" />
    </Link>
  );
}

export default Logo;
