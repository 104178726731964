export function convertDate(timestamp) {
  const convertedDate = new Date(timestamp.seconds*1000);
  const date = convertedDate.getDate();
  const month = convertedDate.getMonth();
  const year = convertedDate.getFullYear();
  const monthDateYear = `${(month+1)}/${date}/${year}`;

  return monthDateYear;
}

export function humanizeTime(timestamp) {
  const convertedDate = new Date(timestamp.seconds*1000);
  const hours = convertedDate.getHours();
  const mins = convertedDate.getMinutes();
  const date = convertedDate.getDate();
  const month = convertedDate.getMonth();
  const year = convertedDate.getFullYear();
  const readableTime = `${hours}:${mins} ${(month+1)}/${date}/${year}`;

  return readableTime;
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isPreview() {
  return process.env.NODE_ENV === 'preview';
}

export function getOptOutEmailTemplate(data) {
  const { fullName, address } = data;
  return `Please remove the name and address info below from your direct mailing list and add it to your Do Not Mail list:

${fullName.trim()}
${address.street1.trim()}
${address.street2.trim()}
${address.city.trim()}, ${address.state.trim()} ${address.zipCode.trim()}

Thanks.`;
}

export function copyEmailTemplateToClipboard(data) {
  copyTextToClipboard(getOptOutEmailTemplate(data));
}

export function copyTextToClipboard(text) {
  const clipboard = navigator.clipboard;
  clipboard.writeText(text);
}

export function firstName(fullName) {
  const names = fullName.trim().split(' ');
  return names[0];
}