import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import * as Config from './constants/Config';
import * as RequestLog from './lib/RequestLog';
import * as Retention from './analytics/Retention';


const firebaseConfig = ['production', 'preview'].includes(process.env.NODE_ENV) ?
{
  apiKey: "AIzaSyC1v13XieZdPsMNP4ZULkr_GgIVa2JuIo4",
  authDomain: "wabi-platform.firebaseapp.com",
  databaseURL: "https://wabi-platform.firebaseio.com",
  projectId: "wabi-platform",
  storageBucket: "wabi-platform.appspot.com",
  messagingSenderId: "243376951785",
  appId: "1:243376951785:web:fb5a192327a79552"
} :
{
  apiKey: "AIzaSyDNelANUZA-Ag2AI759Yhp_nl7-9D7zAmg",
  authDomain: "wabi-dev.firebaseapp.com",
  databaseURL: "https://wabi-dev.firebaseio.com",
  projectId: "wabi-dev",
  storageBucket: "wabi-dev.appspot.com",
  messagingSenderId: "213331534282",
  appId: "1:213331534282:web:41d04d7d88f22557e1ef33",
  measurementId: "G-PPLZZ6H33S"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

window.firebase = firebase;

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export default firebase;

// Helpers
export async function copyRequestTemplatesUnderNewUser(userId) {
  const templatesRef = await firestore.collection('requestTemplates').get();
  for (const doc of templatesRef.docs) {
    const docData = doc.data();
    if (docData.isActive) {
      const data = {
        userId,
        ...docData
      };
      await firestore.doc(`users/${userId}/requests/${doc.id}`).set(data);
    }
  }
}

export async function fetchCurrentUserRequests() {
  let requests = [];
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    const requestsRef = await firestore
      .collection(`users/${userId}/requests`)
      .where('type', '!=', 'custom')
      .get();
    for (const doc of requestsRef.docs) {
      requests.push({
        id: doc.id,
        data: doc.data()
      });
    }
  }

  return requests;
}

export async function fetchCurrentUserData() {
  let userData = {};
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    const userDocRef = await firestore.doc(`users/${userId}`).get();
    userData = userDocRef.data();
  }

  return userData;
}

export async function updateUserData(userId, userData) {
  if (auth.currentUser && auth.currentUser.uid === Config.ADMIN_UUID) {
    await firestore.doc(`users/${userId}`).update(userData);
    return true;
  }

  return false;
}

export async function updateCurrentUserData(userData) {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    await firestore.doc(`users/${userId}`).update(userData);
    return true;
  }

  return false;
}

export function currentUser() {
  if (auth.currentUser) {
    return auth.currentUser;
  }

  return null;
}

export async function createRequest(request) {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    const currentTime = new Date();
    request.title = request.senderName;
    request.type = 'custom';
    request.status = 'inQueue';
    request.requiredDays = 30;
    request.userId = userId;
    request.createdAt = currentTime;
    request.updatedAt = currentTime;
    const docRef = await firestore.collection(`users/${userId}/requests`).add(request);
    const requestId = docRef.id;
    RequestLog.addLog(userId, requestId, 'user', 'create');

    console.log('[API] createRequest', request);
  } else {
    console.error('[API] createRequest: No currentUser');
  }
}

export async function fetchCustomRequests() {
  let requests = [];
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    const requestsRef = await firestore
      .collection(`users/${userId}/requests`)
      .where('type', '==', 'custom')
      .get();
    for (const doc of requestsRef.docs) {
      requests.push({
        id: doc.id,
        data: doc.data()
      });
    }
  }

  return requests;
}

export async function updateUserRequest(requestId, action) {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    let status = '';
    if (action === 'unsubscribe' || action === 'report') {
      status = 'inQueue';
    } else if (action === 'confirm') {
      status = 'confirmedByUser';
    } else if (action === 'cancel') {
      status = 'cancelled';
    } else if (action === 'delete') {
      status = 'deleted';
    }
    const updatedAt = new Date();
    await firestore.doc(`users/${userId}/requests/${requestId}`).set(
      { status, updatedAt }, { merge: true }
    );
    RequestLog.addLog(userId, requestId, 'user', action);
    Retention.logClickRequestActionButton(action);
    console.log('updateUserRequest', action, requestId);
  } else {
    console.error('updateUserRequest: No currentUser');
  }
}

export async function deleteUserRequest(requestId) {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    await firestore.doc(`users/${userId}/requests/${requestId}`).delete();
    console.log('deleteUserRequest', requestId);
  } else {
    console.error('deleteUserRequest: No currentUser');
  }
}

export function subscribeUsersListener() {
  const unsubscribeRequestsListener = firestore.collection('users').onSnapshot(snapshot => {
    const requests = snapshot.docs.map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { id, data };
    });
  
    this.setState({ requests });
  });

  return unsubscribeRequestsListener;
}
