import React from 'react';
import { Link } from 'react-router-dom';

import Auth from '../Auth';


class AuthButton extends React.Component {
  render() {
    const { isAuthed } = this.props;
    return (
      <div className='AuthButton'>
      {
        isAuthed ?
        <div onClick={Auth.signOut}>Sign out</div>
        :
        <div><Link to='/signin'>Sign in</Link></div>
      }
      </div>
    );
  }
}

export default AuthButton;
