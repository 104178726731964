export const STRIPE_PUBLISHABLE_KEY = ['production', 'preview'].includes(process.env.NODE_ENV)
  ? 'pk_live_X6f75B1h8mOfSH8nOlDqStgN00LydCTV1S'
  : 'pk_test_5IQDJR8JE078J041RBryLvhv00R8xyYJwJ';

export const AMPLITUDE_KEY = ['production', 'preview'].includes(process.env.NODE_ENV)
  ? '11e6dcdb383294353efb42434ffb39e6'
  : '92dc394917132e65e645c3f8567be488';

// Basic Plan
export const BASIC_PLAN_PRICE_ID = ['production', 'preview'].includes(process.env.NODE_ENV)
  ? 'price_1Q3u2sFp8bCpYv2w6AxMo8GY'
  : 'price_1IL9v7Fp8bCpYv2wdT1udBH0';

// Premium Yearly Plan
export const SUBSCRIPTION_PRICE_ID = ['production', 'preview'].includes(process.env.NODE_ENV)
  ? 'price_1Q3u1TFp8bCpYv2wyUCIQmqm'
  : 'price_1HI6UzFp8bCpYv2woOFJCBiY';

export const PLANS = {
  'basic': {
    tag: 'basic-plan',
    conversionValue: 16.00
  },
  'premium': {
    tag: 'premium-user',
    conversionValue: 39.99
  }
};

export const NEED_SUBSCRIPTION = true;
export const NUM_OF_FREE_REQUEST = 2;
export const ADMIN_UUID = ['production', 'preview'].includes(process.env.NODE_ENV)
  ? 'unHEA2FO1ZVXMXYHX138db8AG5w1'
  : 'FZXgFMQYbGeOxtpTfOlrTl26X1q2';
export const MODERATOR_UUID = ['production', 'preview'].includes(process.env.NODE_ENV)
  ? 'dDARHSpuU8QNiZAK7pHYtx11DSf2'
  : 'MzVoCfUYfiNQeEelTeqXkifGmkS2';
