
export async function addSubscriber(fullName, email) {
  const data = JSON.stringify({ fullName, email });
  const response = await fetch('/api/mailchimp/subscribers/add', {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: data
  });

  if (response.ok) {
    console.log('addSubscriber: success');
  }
}

export async function addTagToUser(email, tagName) {
  const data = JSON.stringify({ email, tagName });
  const response = await fetch(`/api/mailchimp/tags/add`, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: data
  });

  if (response.ok) {
    console.log('addTagToUser: success');
  }
}

export async function sendNewUserNotification(fullName) {
  const data = JSON.stringify({ fullName });
  const response = await fetch(`/api/mailchimp/notifications/new_user`, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: data
  });

  if (response.ok) {
    console.log('sendNewUserNotification: success');
  }
}
