import React from 'react';

import './Testimonials.scss';
import quote from '../../assets/icons/quote.svg';

const Testimonials = () => {
  return (
    <section id="testimonial" className="TestimonialSection">
      <h2>What Customers Say</h2>

      <div className="Testimonials">
        <div className="Testimonial">
          <div className="Testimonial__content">
            <img src={quote} alt="quote" />
            <p>When I first started using Wabi I was skeptical but I really wanted to clean out my snail mail and go paperless as much as possible. I have to admit that by using Wabi this has helped me to reach my goal. My junk mail has disappeared to a great extent which was my main goal.</p>
            <p>I would recommend everyone try Wabi and see what happens to your junk mail.</p>
          </div>
          <div className="Testimonial__user">
            <div className="name">Lavinia</div>
            <div className="location">Dorchester, MA</div>
          </div>
        </div>

        <div className="Testimonial">
          <div className="Testimonial__content">
            <img src={quote} alt="quote" />
            <p>
              I used Wabi to finally get rid of the endless application invitations from credit card companies. I was very pleased with the results, and with how quickly the mail stopped coming! I would definitely recommend Wabi to anyone who wants to cut down on their junk mail.
            </p>
          </div>
          <div className="Testimonial__user">
            <div className="name">Anna</div>
            <div className="location">Houston, TX</div>
          </div>
        </div>

        <div className="Testimonial">
          <div className="Testimonial__content">
            <img src={quote} alt="quote" />
            <p>
              After moving to a new apartment, I immediately signed up for Wabi. I'm glad I did because my mailbox has been extremely peaceful without the usual large number of direct mailings that follow you around from place to place. 10/10, would do again!
            </p>
          </div>
          <div className="Testimonial__user">
            <div className="name">Tom</div>
            <div className="location">Brooklyn, NY</div>
          </div>
        </div>

        <div className="Testimonial">
          <div className="Testimonial__content">
            <img src={quote} alt="quote" />
            <p>
              Wabi works like a charm!
            </p>
            <p>
              I've already sent over 15 opt-out requests, and I haven't seen any of the junk mail coming back into my mailbox. It just feels great to say goodbye to all of the uninvited flyers from realtors and useless credit card offers - all while saving the planet!
            </p>
          </div>
          <div className="Testimonial__user">
            <div className="name">Owen</div>
            <div className="location">Sunnyvale, CA</div>
          </div>
        </div>

        <div className="Testimonial">
          <div className="Testimonial__content">
            <img src={quote} alt="quote" />
            <p>Wabi is doing a great job to reduce junk mail in my mailbox and saving the environment at the same time. Keep up the great work folks. Thanks!</p>
          </div>
          <div className="Testimonial__user">
            <div className="name">Muhammad</div>
            <div className="location">Louisville, KY</div>
          </div>
        </div>

        <div className="Testimonial">
          <div className="Testimonial__content">
            <img src={quote} alt="quote" />
            <p>Wabi is great! Simplified my life and helping save the environment.</p>
          </div>
          <div className="Testimonial__user">
            <div className="name">Vicki</div>
            <div className="location">Middletown, OH</div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default Testimonials;
