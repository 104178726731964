export function logCreatedRequest() {
  window.amplitude.getInstance().logEvent('[Retention] Created request', {
    'flow': 'retention'
  });
}

export function logVisitedDashboard() {
  window.amplitude.getInstance().logEvent('[Retention] Visited Dashboard', {
    'flow': 'retention'
  });
}

export function logClickRequestActionButton(action) {
  window.amplitude.getInstance().logEvent('Clicked Request Action Button', {
    'category': 'engagement',
    'action': action
  });
}

export function logClickShareButton(platform) {
  window.amplitude.getInstance().logEvent('Clicked Share Button', {
    'category': 'engagement',
    'platform': platform
  });
}
