import React from 'react';
import './FAQs.scss';


const FAQs = () => {
  return (
    <section id="faqs" className="FAQsSection">
      <h2>FAQs</h2>
      <div className="FAQsSection__items">
        <div className="FAQsSection__item">
          <div className="FAQsSection__question">
            How effective is Wabi?
          </div>
          <div className="FAQsSection__answer">
            <p>Unsolicited mail can typically be divided into two categories:</p>
            <ul>
              <li>mail sent using a customer list</li>
              <li>mail that is carpet-bombed across zip codes</li>
            </ul>
            <p>
              Wabi is effective with the first category, but unfortunately, no one can do anything about the second one. The second category is a product of the USPS called Every Door Direct Mail (EDDM). We need legislation to get rid of it.
            </p>
            <p>
              Wabi handles most magazines, catalogs, credit card offers, yellow pages, etc., very well.
            </p>
          </div>
        </div>
        <div className="FAQsSection__item">
          <div className="FAQsSection__question">
            How long does it take for my junk mail to stop?
          </div>
          <div className="FAQsSection__answer">
            <p>
              Once your request has been processed, it can take about 8 weeks to see the mail completely stop. This is because most mailers pre-print and distribute an additional 1 to 3 mails of their campaign upfront, to save on printing and distribution costs.
            </p>
            <p>
              This waiting time is expected of most mailers and is the same if you opt-out of a mailer by contacting them yourself.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
