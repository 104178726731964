import 'whatwg-fetch';
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import store from 'store';


class StepOneForm extends React.Component {
  state = {
    fullName: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: ''
  };

  async verifyAddress(inputData) {
    const data = JSON.stringify({ address: inputData });
    const response = await fetch(`/api/address/verify`, {
      method: 'POST',
      headers: { 'Content-Type': 'text/plain' },
      body: data
    });

    const responseData = await response.json();
    if (responseData.status === 200) {
      const { result } = responseData;
      const { components, deliverability } = result;
      const verifiedAddress = {
        deliverability,
        street1: result.primary_line,
        street2: result.secondary_line,
        city: components.city,
        state: components.state,
        zipCode: components.zip_code
      }
      console.log('verifyAddress OK', verifiedAddress, responseData);
      return verifiedAddress;
    } else {
      console.error('ERROR: verifyAddress', responseData);
      return null;
    }
  }

  render() {
    let initialValues = this.state;
    const userInfo = store.get('userInfo');
    if (userInfo) {
      initialValues = userInfo;
    }
    
    return (
      <div>
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          validate={values => {
            let errors = {};
            if (!values.fullName || values.fullName.trim().split(' ').length < 2) {
              errors.fullName = 'Please enter your full name.';
            }
            if (!values.street1) {
              errors.street1 = 'Please enter the street address.';
            }
            if (!values.city) {
              errors.city = 'Please enter the city name.';
            }
            if (!values.state) {
              errors.state = 'Please enter the state.';
            }
            if (!values.zipCode) {
              errors.zipCode = 'Please enter the zip code.';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let updatedValues = values;
            const verifiedAddress = await this.verifyAddress(values);
            if (verifiedAddress) {
              updatedValues = {
                fullName: values.fullName,
                ...verifiedAddress
              };
            }
            this.setState(updatedValues);
            store.set('userInfo', updatedValues);
            setSubmitting(false);
            this.props.goToNextStep();
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div>
                <Field name="fullName" placeholder="Full Name" />
                <ErrorMessage name="fullName" component="div" className="error" />
              </div>
              <div>
                <Field name="street1" placeholder="Street Address Line 1" />
                <ErrorMessage name="street1" component="div" className="error" />
              </div>
              <div>
                <Field name="street2" placeholder="Street Address Line 2 (Optional)" />
                <ErrorMessage name="street2" component="div" className="error" />
              </div>
              <div>
                <Field name="city" placeholder="City" />
                <ErrorMessage name="city" component="div" className="error" />
              </div>
              <div>
                <Field name="state" placeholder="State" />
                <ErrorMessage name="state" component="div" className="error" />
              </div>
              <div>
                <Field name="zipCode" placeholder="Zip Code" />
                <ErrorMessage name="zipCode" component="div" className="error" />
              </div>
              <button className="button submit-button" type="submit" disabled={isSubmitting}>
                Continue
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
} 

export default StepOneForm;
