import React from 'react';
import { Redirect } from 'react-router-dom';
import store from 'store';

import * as Path from '../../constants/Path';
import * as SignUpFlow from '../../constants/SignUpFlow';
import StepOneForm from '../../components/forms/StepOneForm';


class StepOne extends React.Component {
  componentDidMount() {
    window.amplitude.getInstance().logEvent('Visited Step 1');
  }

  goToNextStep = () => {
    store.set('signupState', 'step2');
    this.props.history.push('/start/step2');
  }

  render() {
    const { isAuthed } = this.props;

    return (
      <div>
        {
          isAuthed ?
          <Redirect to={{
            pathname: Path.DASHBOARD
          }} /> :
          <div className="container">
            <h2>Step 1 of {SignUpFlow.TOTAL_STEPS} / Basic Information</h2>
            <p>Your full name and mailing address are only used for <span>unsubscribing from the junk mailing lists</span>. Your information will be saved securely and will not be traded with any third party.</p>
        
            <StepOneForm goToNextStep={this.goToNextStep} />
          </div>
        }
      </div>
    );
  }
}

export default StepOne;
