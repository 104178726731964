import React from 'react';


class Mission extends React.Component {
  render() {
    return (
      <section className="MissionSection">
        <h2>Our Mission</h2>
        <p>
          <span>Paper junk mail is a waste</span>. It takes 17 trees to make a ton of paper, and over <span>100 million trees</span> get used for junk mail every year in the U.S. Our goal is to get the clutter out of your mailbox, free up your time, and reduce the waste.
        </p>
        <p>
          Join us to <span>regain your privacy control and save our planet</span>.
        </p>
      </section>
    );
  }
}

export default Mission;