import React from 'react';
import { Link } from 'react-router-dom';

import Logo from './Logo';
import AuthButton from './AuthButton';
import SettingMenu from './SettingMenu/SettingMenu';
import * as Path from '../constants/Path';
import * as User from '../lib/User';
import { firestore } from '../firebase';


class Header extends React.Component {
  state = {
    hasCurrentUserSubscribed: true
  };

  subscribeUserListener() {
    const userId = User.currentUserId();
    if (userId) {
      // TODO: unsubscribeUserListener after the user logged out
      this.unsubscribeUserListener = firestore
        .doc(`users/${userId}`)
        .onSnapshot(doc => {
          const data = doc.data();
          const hasCurrentUserSubscribed = !!data.stripeSubscriptionId;
          this.setState({ hasCurrentUserSubscribed });
        });
    }
  }

  componentDidMount() {
    this.subscribeUserListener();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthed !== this.props.isAuthed) {
      this.subscribeUserListener();
    }
  }

  componentWillUnmount() {
    try {
      this.unsubscribeUserListener();
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    const isAuthed = this.props.isAuthed;
    const { hasCurrentUserSubscribed } = this.state;

    return (
      <div className='Header'>
        <div className='Header__menu'>
          <Logo isAuthed={isAuthed} />
          {/* <Link to='/learn'>Learn</Link> */}
        </div>
        {
          isAuthed && !hasCurrentUserSubscribed ?
          <Link to={Path.SUBSCRIPTION_PAGE} className='no-style'>
            <span className='Button'>Upgrade</span>
          </Link> : null
        }
        {
          isAuthed ?
          <SettingMenu /> :
          <AuthButton isAuthed={isAuthed} />
        }
      </div>
    );
  }
}

export default Header;
