import 'whatwg-fetch';
import store from 'store';

import * as Api from '../constants/Api';
import * as Config from '../constants/Config';
import firebase from '../firebase';
import { fetchCurrentUserData, updateCurrentUserData } from '../firebase';
import * as Mailchimp from '../api/Mailchimp';
import * as Util from '../Util';

export function hasAccessToAdmin() {
  const userId = currentUserId();
  const allowlist = [Config.ADMIN_UUID, Config.MODERATOR_UUID];
  return allowlist.includes(userId);
}

export function accessLevel() {
  const userId = currentUserId();
  const admins = [Config.ADMIN_UUID];
  const moderators = [Config.MODERATOR_UUID];
  if (admins.includes(userId)) {
    return 'admin';
  } else if (moderators.includes(userId)) {
    return 'moderator';
  }
}

export function isAdmin() {
  const userId = currentUserId();
  const admins = [Config.ADMIN_UUID];
  return admins.includes(userId);
}

export function isModerator() {
  const userId = currentUserId();
  const moderators = [Config.MODERATOR_UUID];
  return moderators.includes(userId);
}

export async function decrementNumOfFreeRequest() {
  const user = await currentUser();
  let { numOfFreeRequest, email } = user;
  if (numOfFreeRequest === undefined) {
    numOfFreeRequest = Config.NUM_OF_FREE_REQUEST;
  }

  if (numOfFreeRequest > 0) {
    numOfFreeRequest -= 1;
  }

  if (Util.isProduction() && numOfFreeRequest <= 0) {
    Mailchimp.addTagToUser(email, 'free-requests-used');
  }

  updateCurrentUser({
    numOfFreeRequest
  });
}

export function setCurrentUserAsSubscriber() {
  updateCurrentUserData({ isSubscriber: true });
}

export function updateCurrentUser(userData) {
  const isSuccess = updateCurrentUserData(userData);
  if (isSuccess) {
    cacheCurrentUser(userData);
  }
}

export async function currentUser() {
  const userData = await fetchCurrentUserData();
  const userId = currentUserId();
  const currentUser = { userId, ...userData };
  return currentUser;
}

export function cacheCurrentUser(user) {
  const userId = currentUserId();
  const currentUser = { userId, ...user };
  store.set('currentUser', currentUser);
}

export function cachedCurrentUser() {
  return store.get('currentUser');
}

export function currentUserId() {
  const auth = firebase.auth()
  if (auth.currentUser) {
    return auth.currentUser.uid;
  }
  return null;
}

export async function hasCurrentUserSubscribed() {
  const user = await currentUser();
  return !!user.stripeSubscriptionId;
}

export async function hasBasicPlan() {
  const user = await currentUser();
  return user.plan === 'basic';
}

export async function createCustomer(name, email) {
  const response = await fetch(Api.CREATE_CUSTOMER, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name,
      email
    })
  });

  if (response.ok) {
    const data = await response.json();
    return data.customer;
  } else {
    return null;
  }
    // .then(response => {
    //   console.log('response', response);
    //   return response.json();
    // })
    // .then(result => {
    //   return result;
    // });
}
