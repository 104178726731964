import React from 'react';

import './Benefits.scss';

const Benefits = () => {
  return (
    <section id="benefits" className="BenefitsSection">
      <h2>Reduce <span className="number">76%</span> of your junk mail in 4 weeks</h2>
    </section>
  );
};

export default Benefits;