import React from 'react';

import Auth from '../../Auth';
import './SettingMenu.scss';


class SettingMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };
    this.toggleMenuVisibility = this.toggleMenuVisibility.bind(this);
  }

  toggleMenuVisibility() {
    const isVisible = !this.state.isVisible;
    this.setState({ isVisible });
  }

  render() {
    const { isVisible } = this.state;

    return (
      <div className="SettingMenu">
        <div onClick={this.toggleMenuVisibility}>Setting</div>
        {
          isVisible ?
          <ul>
            {/* <li><Link to="/recipients">Recipients</Link></li> */}
            <li onClick={Auth.signOut}>Sign Out</li>
          </ul> :
          null
        }
      </div>
    );
  }
}

export default SettingMenu;
