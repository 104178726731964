import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import store from 'store';

import * as Config from '../../constants/Config';
import * as Util from '../../Util';
import firebase from '../../firebase';
import { firestore, copyRequestTemplatesUnderNewUser } from '../../firebase';
import * as User from '../../lib/User';
import * as Mailchimp from '../../api/Mailchimp';


class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formError: null
    };
  }

  render() {
    const { formError } = this.state;

    return (
      <div>
        <Formik
          validateOnChange={false}
          initialValues={{ email: '', password: '', newsletter: true }}
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Please enter your email address.';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (!values.password) {
              errors.password = 'Please enter the password.';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let formError = null;
            const email = values.email.trim().toLowerCase();
            const password = values.password;
            try {
              const data = await firebase.auth().createUserWithEmailAndPassword(email, password);
              const userId = data.user.uid;
              const { fullName, ...address } = store.get('userInfo');
              const createdAt = new Date();
              const numOfFreeRequest = Config.NUM_OF_FREE_REQUEST;
              const userData = {
                email, fullName, address, createdAt, numOfFreeRequest
              };
              await firestore.collection('users').doc(`${userId}`).set(userData);
              await copyRequestTemplatesUnderNewUser(userId); 
              User.cacheCurrentUser(userData);

              if (Util.isProduction()) {
                Mailchimp.addSubscriber(fullName, email); // no need to await
                Mailchimp.sendNewUserNotification(fullName);
                window.amplitude.getInstance().logEvent('Signed up');
                window.gtag('event', 'sign_up', {
                  method: 'email'
                });
                window.gtag('event', 'ua_sign_up', {
                  method: 'email'
                });
                window.fbq('track', 'Lead', {
                  value: 2,
                  currency: 'USD',
                });

                try {
                  window.FS.identify(userId, {
                    displayName: fullName,
                    email: email
                  });
                  window.FS.event('Sign-up');
                } catch(error) {
                  console.error(error);
                }
              }
              this.props.goToNextStep();
            } catch(error) {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              if (errorCode === 'auth/email-already-in-use') {
                formError = 'Email has already been used.';
              } else if (errorCode === 'auth/invalid-email') {
                formError = errorMessage;
              } else if (errorCode === 'auth/operation-not-allowed') {
                formError = errorMessage;
              } else if (errorCode === 'auth/weak-password') {
                formError = errorMessage;
              } else {
                formError = errorMessage;
              }
              this.setState({ formError });
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div>
                <Field type="email" name="email" placeholder="Email" autoComplete="off" autoCorrect="off" spellCheck="false" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div>
                <Field type="password" name="password" placeholder="Password" autoComplete="off" />
                <ErrorMessage name="password" component="div" className="error" />
              </div>
              {/* <div>
                <Field type="checkbox" name="newsletter" checked={values.newsletter} />
                I'm interested in receiving curated newsletter about how to reduce waste and simplify my life.
              </div> */}
              <div style={{ marginTop: '10px' }}>
                By clicking Sign up, you agree to our <Link to="/terms" target="_blank">Terms of Service</Link> and <Link to="/privacy" target="_blank">Privacy Policy</Link>.
              </div>
              { 
                formError ? 
                <div className="form-error-message">{formError}</div> : null
              }
              <button className="button submit-button" type="submit" disabled={isSubmitting}>
                { isSubmitting ? "Loading..." : "Sign up" }
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default SignUpForm;
