import React from 'react';

import './Impact.scss';

const Impact = () => {
  return (
    <section id="impact" className="ImpactSection">
      <h2>Save <span className="number">10,177+</span> trees and counting.</h2>
      <h2>Plant a tree for every customer.</h2>
    </section>
  );
};

export default Impact;