import { firestore } from '../firebase';

export async function addLog(userId, requestId, creator, status) {
  const log = {
    creator,
    status,
    createdAt: new Date()
  };
  try {
    await firestore.collection(`users/${userId}/requests/${requestId}/logs`).add(log);
    console.log('[API] addLog', log);
    return true;
  } catch(error) {
    console.error('[API] addLog', error);
    throw new Error(error);
  }
}

export async function getLogs(userId, requestId) {
  const logs = [];
  try {
    const logsRef = await firestore
      .collection(`users/${userId}/requests/${requestId}/logs`)
      .orderBy('createdAt', 'desc').get();
    for (const doc of logsRef.docs) {
      logs.push({
        id: doc.id,
        data: doc.data()
      });
    }
    console.log('[API] getLogs', userId, requestId, logs);
    return logs;
  } catch(error) {
    console.error('[API] getLogs', error);
    throw new Error(error);
  }
}