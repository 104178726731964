import React from 'react';
import store from 'store';

import SignUpForm from '../../components/forms/SignUpForm';
import * as SignUpFlow from '../../constants/SignUpFlow';
import * as Path from '../../constants/Path';


class StepTwo extends React.Component {
  componentDidMount() {
    window.amplitude.getInstance().logEvent('Visited Step 2');

    const signupState = store.get('signupState');
    if (signupState === 'done') {
      this.props.history.push('/dashboard');
    }
  }

  goToNextStep = () => {
    if (SignUpFlow.TOTAL_STEPS === 2) {
      this.goToDashboard();
    } else if (SignUpFlow.TOTAL_STEPS === 3) {
      this.goToPaymentStep();
    }
  }

  goToDashboard = () => {
    store.set('signupState', 'done');
    this.props.history.push(Path.DASHBOARD);
  }

  goToPaymentStep = () => {
    store.set('signupState', 'step3');
    this.props.history.push('/start/step3');
  }

  render() {
    return (
      <div>
        <div className="container">
          <h2>Step 2 of {SignUpFlow.TOTAL_STEPS} / Create Account</h2>
          <p>To keep you posted about the opt-out progress. We only use your email for important communication.</p>
      
          <SignUpForm goToNextStep={this.goToNextStep} />
        </div>
      </div>
    );
  }
}

export default StepTwo;
